import axios from "@/helpers/axios";

const clients = {
  list: function (options) {
    return axios
      .get('client/list/', options)
      .then(({ data }) => data.data);
  },
  create: function (options) {
    return axios
      .post('client/create/', options)
      .then(({ data }) => data);
  },
};

export {clients};
