<template>
  <q-card style="width: 1000px; max-width: 80vw;">
    <q-card-section>
      <div class="text-h6">{{ workData.name ? workData.name : '' }}</div>
    </q-card-section>

    <q-card-section class="q-pt-none q-gutter-md">
      <q-input
          filled
          dense
          v-model="workData.name"
          style="width: calc(100% - 0);"/>

      <q-select filled
                dense
                v-model="workAssignee"
                label="Ответственный"
                :options="userList"
                :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                style="width: 160px"/>

      <q-select filled dense v-model="taskData"
                label="Задача"
                :options="taskList"
                :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                style="width: 300px"/>

      <div class="q-gutter-x-md row items-start">

        <div>
          <div class="row">
            <q-input
                filled
                dense
                disable
                v-model="dateStart"
                label="Дата"
                style="width: 160px"
            />
            <q-btn icon="event" round>
              <q-popup-proxy cover transition-show="scale" transition-hide="scale" ref="qDateProxy">
                <q-date v-model="dateStart" mask="YYYY-MM-DD" minimal @update:model-value="$refs.qDateProxy.hide()"/>
              </q-popup-proxy>
            </q-btn>
          </div>

          <div>
            <q-badge color="teal">{{ }}</q-badge>
          </div>
        </div>

        <div>
          <div class="row">
            <q-input
                filled
                dense
                label="Длительность, мин"
                v-model="newJobDurationString"
            />
          </div>

          <q-badge color="teal">
            минуты: {{ newJobDurationMinutes }}
          </q-badge>
          <q-badge color="purple">
            {{ newJobDurationMinutesBackToString }}
          </q-badge>
        </div>

        <div>
          <div class="row">
            <q-input
                filled
                dense
                label="Длительность, мин"
                v-model="newJobDurationClientString"
            />
          </div>

          <q-badge color="teal">
            минуты: {{ newJobDurationMinutesClient }}
          </q-badge>
          <q-badge color="purple">
            {{ newJobDurationMinutesClientBackToString }}
          </q-badge>
        </div>
      </div>
    </q-card-section>



    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="закрыть" v-close-popup/>
      <q-btn flat label="сохранить" @click="updateWork" v-close-popup/>
    </q-card-actions>

<!--    <q-card-section class="q-pt-none">
      <pre>{{ workData }}</pre>
    </q-card-section>-->

  </q-card>
</template>

<script>
import {work} from "@/api/work";
import {ref} from "vue";
import {duration} from "@/helpers/duration";

export default {
  name: "WorkSingle",
  props: {
    workData: null,
    userList: null,
    taskList: null,
    // TODO: так не делать — перезагрузка списка в основном окне
    listWorks: function (){},
  },
  data() {
    return {
      workName: this.workData.name,
      // userId: this.workData.user_id,
      workAssignee: null,
      taskData: null,

      dateStart: ref('YYYY-MM-DD'),

      newJobDurationString: '',
      newJobDurationMinutes: 0,
      newJobDurationMinutesBackToString: ' ',
      newJobDurationClientString: '',
      newJobDurationMinutesClient: 0,
      newJobDurationMinutesClientBackToString: ' ',
    }
  },
  mounted() {
    this.workAssignee = this.userList.filter(obj => {
      return obj.id === this.workData.user_id;
    })[0];
    this.taskData = this.taskList.filter(obj => {
      return obj.id === this.workData.task_id;
    })[0];
    this.newJobDurationMinutes = this.workData.duration_minutes;
    this.newJobDurationString = duration.minutesToString(this.newJobDurationMinutes);
    //
    this.newJobDurationMinutesClient = this.workData.client_duration_minutes;
    this.newJobDurationClientString = duration.minutesToString(this.newJobDurationMinutesClient);
    //
    this.dateStart = this.workData.started_at;
  },
  watch: {
    newJobDurationString(val) {
      this.updateNewJobDurationMinutes();
    },
    newJobDurationClientString(val) {
      this.updateNewJobDurationMinutes();
    },
  },
  methods: {
    async updateWork(options) {
      try {
        const result = await work.update({
          id: this.workData.id,
          name: this.workData.name,
          user_id: this.workAssignee.id,
          task_id: this.taskData.id,
          duration_minutes: this.newJobDurationMinutes,
          client_duration_minutes: this.newJobDurationMinutesClient,
          started_at: this.dateStart,
        });
      } catch (error) {
        if (error.response.status !== 404) {}
      } finally {
        this.listWorks();
      }
    },
    // TODO: дубль от Timer.vue
    updateNewJobDurationMinutes() {
      this.newJobDurationMinutes = duration.stringToMinutes(this.newJobDurationString)
      this.newJobDurationMinutesBackToString = duration.minutesToString(this.newJobDurationMinutes);
      //
      this.newJobDurationMinutesClient = duration.stringToMinutes(this.newJobDurationClientString)
      this.newJobDurationMinutesClientBackToString = duration.minutesToString(this.newJobDurationMinutesClient);
    },
  }
}
</script>

<style scoped>

</style>
