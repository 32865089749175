import axios from "@/helpers/axios";

const tags = {
  list: function (options) {
    return axios
      .get('tag/list/', options)
      .then(({ data }) => data[0].data);
  },
  create: function (options) {
    return axios
      .post('tag/create/', options)
      .then(({ data }) => data);
  },
};

export {tags};
