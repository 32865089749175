<template>
  <div class="q-pa-md">
    <h6 style="margin: 0">Работы</h6>

    <div class="q-gutter-md q-my-lg">

      <div class="q-gutter-x-md row items-start">
        <q-input
            filled
            dense
            v-model="newJobName"
            label="Что сделано"
            style="width: calc(100% - 340px)"
            :rules="[ val => val.length <= 255 || 'Больше 255: пиши в описание']"
        />

        <div style="width: calc(100% - 340px)">
          <ckeditor :editor="editor"
                    v-model="newJobDescription"
                    :config="editorConfig"
          ></ckeditor>
        </div>

        <q-select filled dense v-model="newJobProjectId"
                  label="Проект"
                  :options="projectList"
                  :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                  :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                  style="width: 300px"/>
      </div>

      <div class="q-gutter-x-md row items-start">

        <div>
          <div class="row">
            <q-input
                filled
                dense
                disable
                v-model="startDate"
                label="Дата"
                style="width: 160px"
            />
            <q-btn icon="event" round>
              <q-popup-proxy cover transition-show="scale" transition-hide="scale" ref="qDateProxy">
                <q-date v-model="startDate" mask="YYYY-MM-DD" minimal @update:model-value="$refs.qDateProxy.hide()"/>
              </q-popup-proxy>
            </q-btn>
          </div>

          <!--          <div>
                      <q-badge color="teal">{{ dateStart }}</q-badge>
                    </div>-->
        </div>

        <div>
          <div class="row">
            <q-input
                filled
                dense
                label="Длительность, мин"
                v-model="newJobDurationString"
            />
            <q-btn-dropdown outline padding="xs md">
              <q-list>
                <q-item clickable v-close-popup @click="updateDuration('15м')">
                  <q-item-section>
                    <q-item-label>15 минут</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="updateDuration('30м')">
                  <q-item-section>
                    <q-item-label>30 минут</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="updateDuration('45м')">
                  <q-item-section>
                    <q-item-label>45 минут</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="updateDuration('1ч')">
                  <q-item-section>
                    <q-item-label>1 час</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="updateDuration('1ч 15м')">
                  <q-item-section>
                    <q-item-label>1 час 15 минут</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="updateDuration('1ч 30м')">
                  <q-item-section>
                    <q-item-label>1 час 30 минут</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="updateDuration('1ч 45м')">
                  <q-item-section>
                    <q-item-label>1 час 45 минут</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="updateDuration('2ч')">
                  <q-item-section>
                    <q-item-label>2 часа</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="updateDuration('3ч')">
                  <q-item-section>
                    <q-item-label>3 часа</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="updateDuration('4ч')">
                  <q-item-section>
                    <q-item-label>4 часа</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </div>
          <!--          <q-badge color="teal">
                      минуты: {{ newJobDurationMinutes }}
                    </q-badge>
                    <q-badge color="purple">
                      {{ newJobDurationMinutesBackToString }}
                    </q-badge>-->
        </div>

        <!--        <q-toggle v-model="billable" label="Оплачиваемая"/>-->

        <q-select filled dense v-model="newJobTaskId"
                  label="Задача"
                  :options="taskListFiltered"
                  :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                  :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                  use-input
                  hide-selected
                  fill-input
                  input-debounce="0"
                  @filter="filterTasksByName"
                  style="width: 300px"/>

        <q-select filled
                  dense
                  v-model="newJobAssignee"
                  label="Пользователь"
                  :options="userList"
                  :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                  :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                  style="width: 160px"/>

        <q-btn type="submit" color="primary" :label="workCreationInProgress ? 'Трекаю, подождите...' : 'Добавить работу'"
               :disabled="!(newJobDurationMinutes > 0)||(!newJobProjectId.id)||workCreationInProgress||!newJobAssignee||(newJobName==''&&newJobDescription=='')"
               @click="createWork()"/>

      </div>
    </div>

<!--    <pre>{{workList}}</pre>-->

<!--    <div v-for="work in workList">{{work.name}}</div>-->

<!--        @row-click="onRowClick"-->
    <q-table
        :is-loading="isLoading"
        :rows="workList || []"
        :columns="columns"
        :pagination="{ page: 1, rowsPerPage: 0 }"
        hide-bottom
        row-key="name"
        :visible-columns="user && [1,3].includes(user.id) ? visibleColumnsSuperadmin : visibleColumnsUser"
    >
      <template v-slot:body-cell-name="props">
        <q-td :props="props"
              :label="props.value"
              :class="'wrap-me'"
              v-html="props.value">
        </q-td>
      </template>

      <template v-slot:body-cell-task="props">
        <q-td :props="props"
              :label="props.value"
              :class="'wrap-me'">
          {{props.value}}
        </q-td>
      </template>

      <template v-slot:body-cell-action="props">
        <td align="right">
          <q-btn-dropdown dense flat color="grey" dropdown-icon="more_horiz" @click.stop="">
            <q-list>
              <q-item clickable v-close-popup @click.stop="editWork(props.row.id)">
                <q-item-section avatar>
                  <q-icon name="edit"></q-icon>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Редактировать</q-item-label>
                </q-item-section>
              </q-item>
              <q-separator />
              <q-item clickable v-close-popup @click.stop="deleteWork(props.row.id)">
                <q-item-section avatar>
                  <q-icon name="delete" color="red"></q-icon>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Удалить</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </td>
      </template>
    </q-table>
  </div>

  <q-dialog v-model="fullWidthHeight">
    <work-single :work-data="singleWorkData"
                 :user-list="userList"
                 :task-list="taskList"
                 :list-works="listWorks"/>
  </q-dialog>

<!--  <pre>{{taskListFiltered}}</pre>-->
</template>

<script>
import {ref} from "vue"
import {work} from "@/api/work";
import {project} from "@/api/project";
import {task} from "@/api/task";
import {user} from "@/api/user";
import {duration} from "@/helpers/duration";
import WorkSingle from "@/components/tasks/WorkSingle.vue";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mapGetters } from 'vuex'

const columns = [
  {name: 'name', required: true, label: 'Описание', align: 'left',
    field: row => row.description ? row.description : row.name,
    format: val => `${val}`,
  },
  {name: 'task', label: 'Задача', align: 'left', field: 'task_name'},
  {name: 'duration', label: 'Время', align: 'left', field: 'durationStr'},
  {name: 'duration_c', label: 'ВремяЧ', align: 'left', field: 'durationStrC'},
  {name: 'timeStart', label: 'Дата', align: 'left', field: 'started_at',
    format: val => {
      return val.replace(' 00:00:00', '');  // todo: сделать нормальный формат даты
    },
    sortable: true,
    sortOrder: 'da'
  },
  {name: 'user', label: 'User', align: 'left', field: 'user_name'},
  {name: 'project', label: 'Проект', align: 'left', field: 'project_name'},
  {name: "action", label: null, align: "right"},
]

// let newJobDurationMinutes = 0;

export default {
  name: "Timer",
  components: {
    mapGetters,
    WorkSingle,
    ckeditor: CKEditor.component
  },
  computed: {
    ...mapGetters({
      user: "account/user",
    }),
    task() {
      return task
    }
  },
  setup() {
    return {

      //date picker

      startDate: ref('YYYY-MM-DD'),
      // dateStart: setNow(),


      visibleColumnsSuperadmin: [ 'name', 'task', 'duration', 'duration_c', 'timeStart', 'user', 'project', 'action' ],
      visibleColumnsUser:       [ 'name', 'task', 'duration',               'timeStart', 'user', 'project', 'action' ],
    }
  },
  data() {
    return {

      input: '',
      date: '2018/11/03',

      columns,
      workList: [],
      projectList: [],
      taskList: [],
      taskListForProject: [],
      taskListFiltered: [],
      userList: {},

      newJobName: '',
      newJobDescription: '',
      newJobProjectId: null,
      newJobAssignee: null,
      newJobTaskId: null,
      newJobDurationString: '',
      newJobDurationMinutes: 0,
      newJobDurationMinutesBackToString: ' ',
      newJobDurationMinutesClient: 0,

      newJobBillable: true,

      singleWorkData: null,

      fullWidthHeight: false,

      editor: ClassicEditor,
      editorConfig: {},

      workCreationInProgress: false,
    }
  },
  created() {
    // console.log(this.user.id);
    // this.userList = this.$settings.userList;
    this.userList = [1, 3].includes(this.user.id) ? this.$settings.userList : this.$settings.userList.filter(obj => {
      return obj.id === this.user.id
    });
    if (![1, 3].includes(this.user.id)) {
      // назначить единственного исполнителя ответственным
      this.newJobAssignee = this.userList[0];
    };
    this.listProjects();
    this.listTasks();
    this.setCurrentDate();
    // this.listWorks();
  },
  watch: {
    newJobDurationString(val) {
      this.updateNewJobDurationMinutes();
    },
    newJobProjectId(val) {
      this.taskListForProject = this.taskList.filter(function(task) {
        return task.group.project_id == val.id;
      });
      this.taskListFiltered = this.taskListForProject;
      this.newJobTaskId = null;
      this.listWorks();
    },
    newJobTaskId(val) {
      this.listWorks();
    },
    newJobAssignee(val) {
      this.listWorks();
    },
    // newJobDescription(val) {
    //   this.newJobName = this.stripHtml(val);
    // },
  },
  methods: {
    async listWorks() {
      console.log('listWorks')
      try {
        this.isLoading = true;

        const options = {};

        if (this.newJobProjectId?.id) {
          options.project_id = this.newJobProjectId?.id
        } else {
          return
        }
        if (this.newJobTaskId?.id) {
          options.task_id = this.newJobTaskId?.id
        }
        if (this.newJobAssignee?.id) {
          options.user_id = this.newJobAssignee.id
        }

        const result = await work.list(options);
        // let   resultFiltered = result;

        // TODO: вынести из загрузки
        result.forEach((el) => {
          el.durationStr  = duration.minutesToString(el.duration_minutes);
          el.durationStrC = duration.minutesToString(el.client_duration_minutes);
          el.project_name = el.project.name; //this.getProjectNameById(el.project_id);
          el.task_name    = el.task.name; //this.getTaskNameById(el.task_id);
          el.user_name    = el.user.name; //this.getUserNameById(el.user_id);
        });

        // todo: временно, пока не починим фильтрацию на бэке
        // if (this.newJobProjectId?.id) {
          // console.log(this.newJobProjectId?.id)
          // resultFiltered = resultFiltered.filter(obj => {
          //   return obj.project_id === this.newJobProjectId.id
          // })

          // if (this.newJobTaskId?.id) {
            // console.log(this.newJobTaskId.id)
            // resultFiltered = resultFiltered.filter(obj => {
            //   return (obj.task_id === this.newJobTaskId.id)
            // })
          // }
        // }
        // console.log(resultFiltered);


        // if (this.newJobAssignee?.id) {
          // console.log(this.newJobAssignee.id)
          // resultFiltered = resultFiltered.filter(obj => {
          //   return obj.user_id === this.newJobAssignee.id
          // })
        // }
        // console.log(resultFiltered);

        // todo: так быть должно
        this.workList = result;
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.isLoading = false;
      }
    },
    /*getProjectNameById(id) {
      if(this.projectList.length === 0) {
        return '—';
      }
      let project = this.projectList.filter(obj => {
        return obj.id === id;
      })[0];
      return project?.name ?? '---';
    },
    getTaskNameById(id) {
      if(this.taskList.length === 0) {
        return '—';
      }
      let task = this.taskList.filter(obj => {
        return obj.id === id;
      })[0];
      return task.name;
    },
    getUserNameById(id) {
      if(this.userList.length === 0) {
        return '—';
      }
      let user = this.userList.filter(obj => {
        return obj.id === id;
      })[0];
      return user?.name ?? '---';
    },*/
    async createWork() {
      try {
        this.workCreationInProgress = true;

        const result = await work.create({
          name: this.newJobName!='' ? this.newJobName : this.newJobDescription.substring(0,255),
          description: this.newJobDescription,
          project_id: this.newJobProjectId.id,
          task_id:  this.newJobTaskId?.id ?? null,
          duration_minutes: this.newJobDurationMinutes,
          client_duration_minutes: this.newJobDurationMinutesClient > 0 ? this.updateNewJobDurationMinutes() : this.newJobDurationMinutes,
          started_at: this.startDate,
          user_id: this.newJobAssignee.id,

          billable: true, // не должно быть обязательным
        });

        if (result.task_id) {
          console.log("work created: " + result.id);
        }
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.listWorks();
        this.workCreationInProgress = false;
      }
    },
    async deleteWork(id) {
      try {
        console.log(id);
        const result = await work.delete({
          id: id,
        });
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.listWorks();
      }
    },
    async getOneWork(id) {
      try {
        const result = await work.getOne({
          id: id,
        });

        this.singleWorkData = result;
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.fullWidthHeight = true;
      }
    },
    async listProjects() {
      try {
        this.isLoading = true;
        this.projectList = [];

        const result = await project.list();

        if (![1,3].includes(this.user.id) && this.user.settings.allow?.project?.allowIds) {
          this.projectList = result.filter(obj => {
            return this.user.settings.allow?.project?.allowIds.includes(obj.id);
          });
        } else {
          this.projectList = result;
        }
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.isLoading = false;
        this.listWorks();
      }
    },
    async listTasks() {
      try {
        const result = await task.list({
          // project_id: this.newJobProjectId?.id
        });

        this.taskList = result;
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {}
    },
    // TODO: дублируется в WorkSingle.vue и WorkAddModal.vue
    updateNewJobDurationMinutes() {
      this.newJobDurationMinutes = duration.stringToMinutes(this.newJobDurationString)
      this.newJobDurationMinutesBackToString = duration.minutesToString(this.newJobDurationMinutes);
    },
    updateDuration(minutes) {
      this.newJobDurationString = minutes;
    },
    setCurrentDate () {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd;

      this.startDate = today;
    },
    onRowClick (evt, row) {
      this.getOneWork(row.id);
    },
    editWork(id) {
      this.getOneWork(id);
    },
    // TODO: перерисовывать состояние здесь, не передавая функцию в single
    // onSingleSaved() {
    //   this.listWorks();
    // }
    //
    filterTasksByName (val, update, abort) {
      update(() => {
        const needle = val.toLowerCase();

        this.taskListFiltered = this.taskListForProject.filter(obj => {
          return obj.name.toLowerCase().indexOf(needle) > -1;
        })

        // this.taskListFiltered = [];
        //
        // this.taskListFiltered = this.taskList.filter(function(task) {
        //   return task.group.project_id == val.id && v => v.toLowerCase().indexOf(needle) > -1;
        // });
      })
    },
    stripHtml(html) {
      let regex = /(<([^>]+)>)/ig;
      return html.replace(regex, "");
    },
  },
}

</script>

<style lang="scss" scoped>
  .wrap-me {
    white-space: normal;
  }
</style>
