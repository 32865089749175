import axios from "@/helpers/axios";

const task = {
  create: function (options) {
    return axios
        .post('task/create/', options)
        .then(({data}) => data);
  },
  delete: function (options) {
    return axios
        .post('task/delete/' + options.id, options)
        .then(({data}) => data);
  },
  list: function (options) {
    let addGetParams = '';
    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        if( addGetParams == '' ) addGetParams += '?';
        else addGetParams += '&';
        addGetParams += `${key}=${options[key]}`;
      }
    }
    return axios
        .get('task/list/' + addGetParams, options)
        .then(({data}) => data.data);
  },
  update: function (options) {
    return axios
        .post('task/update/' + options.id, options)
        .then(({data}) => data.data);
  },
  getOne: function (options) {
    return axios
        .get('task/' + options.id, options)
        .then(({data}) => data);
  },
};

export {task};
