<template>
  <div class="row">
    <div class="col-9">
      <div class="q-pa-md">
        <h5 style="margin-top: 0">Метки</h5>

        <q-table
            :is-loading="isLoading"
            :rows="tagsList || []"
            :columns="columns"
            row-key="name"
        >
          <template v-slot:body-cell-name="props">
            <q-td>
              <q-badge
                  :style="`display: inline-block; background-color: ${props.row.color} !important;`"
              >{{props.value}}</q-badge>
            </q-td>
          </template>
        </q-table>

      </div>
    </div>
    <div class="col-3">
      <div class="q-pa-md">
        <q-card class="my-card">
          <q-card-section>
            <h6 style="margin-top: 0">+ Новая метка</h6>

            <div class="q-gutter-md" style="max-width: 500px">

              <q-input
                  filled
                  v-model="newTagName"
                  label="Название"
                  lazy-rules
                  :rules="[ val => val && val.length > 0 || 'Please type something']"
              />

<!--              <q-select filled
                        v-model="clientForNewProject"
                        label="Клиент"
                        :options="projectsListList"
                        :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                        :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"/>-->

              <q-color
                  v-model="newTagColor"
                  default-view="palette"
                  no-header-tabs
                  no-footer
                  :palette="[
                    '#8077f1', '#6a85ff', '#40a6e5', '#3fb1b2', '#64c6a2', '#f9be34',
                    '#e78845', '#dc646a', '#f17ead', '#c580e6', '#bba399', '#595d66'
                  ]"
                  class="my-picker"
              />

              <div>
                <q-btn type="submit" color="primary" label="Создать"
                       @click="createTag()"/>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import {tags} from "@/api/tags";

export default {
  name: "Tags",
  setup() {
    return {
    }
  },
  data() {
    return {
      projectsList: [],
      columns: [
        {name: 'name', required: true, label: 'Тег', align: 'left', field: row => row.name},
        {name: 'color', label: 'Цвет', align: 'left', field: 'color'},
        // {name: 'actions', label: 'Действия', align: 'left', field: 'actions'},
      ],
      tagsList: [],
      isLoading: true,

      newTagName: '',
      newTagColor: '#bba399',
    }
  },
  created() {
    this.fetchTags();
  },
  methods: {
    async fetchTags() {
      try {
        this.isLoading = true;
        this.tagsList = [];

        let result = await tags.list();

        this.tagsList = result;
      } catch (error) {
        if (error) {
          console.log('ERROR');
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.isLoading = false;
      }
    },
    async createTag() {
      try {
        const result = await tags.create({
          name: this.newTagName,
          color: this.newTagColor,
          // client_id: this.clientForNewProject.id,
          // client_name: this.clientForNewProject.name  // это нужно только для локального апи
        });
      } catch (error) {
        if (error) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.fetchTags();
      }
    },
  },
}
</script>

<style lang="sass" scoped>

</style>
