import axios from "@/helpers/axios";

const notification = {
  list: function (options) {
    let addGetParams = '';
    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        if( addGetParams == '' ) addGetParams += '?';
        else addGetParams += '&';
        addGetParams += `${key}=${options[key]}`;
      }
    }
    return axios
        .get('notification/list/' + addGetParams, options)
        .then(({data}) => data[0].data);
  },
  // notification/mark-read-by-task/{task}
  // notification/mark-read-all
  markReadByTask: function (taskId) {
    return axios
    .post('notification/mark-read-by-task/' + taskId)
    .then(({data}) => data.data);
  },
  markReadAll: function (taskId) {
    return axios
    .post('notification/mark-read-all/')
    .then(({data}) => data.data);
  },
};

export {notification};
