<template>
  <div class="q-pa-md">
    <div class="page-title-row">
      <div class="row">
        <router-link :to="'/projects/'+$route.params.project_id+'/doc'">
          <q-icon
              name="arrow_back"
              size="md"
          />
        </router-link>
        <h6 style="margin: 0 0 0 20px">{{ doc?.name }}</h6>

        <q-btn size="md"
               padding="xs md"
               label="Редактировать"
               icon="o_edit"
               :to="'/projects/'+$route.params.project_id+'/doc/'+$route.params.id+'/edit'"
               style="margin-left: auto"/>
      </div>

      <div class="q-my-lg">
        <div v-if="isLoading" class="loading">Грузим...</div>

        <div v-if="error" class="error">{{ error }}</div>

        <div v-if="doc" class="content">

<!--          <div v-html="doc.body" class="doc-body"></div>-->

          <q-card flat>
            <q-card-section v-html="doc.body" />
          </q-card>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { document } from '@/api/document'

export default {
  name: 'Document',
  data() {
    return {
      isLoading: false,
      error: null,

      doc: null,
    }
  },
  created () {
    this.$watch(
        () => this.$route.params,
        () => {
          this.fetchDocument()
        },
        { immediate: true }
    )
  },
  methods: {
    async fetchDocument(options) {
      this.error = this.post = null
      this.isLoading = true

      try {
        options = options ?? {};
        options.id = this.$route.params.id;

        const result = await document.getOne(options);

        this.isLoading = false

        this.doc = result;
      } catch (error) {
        if (error.response?.status !== 404) {}
      } finally {
        this.isloading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.doc-body {

}
:deep table {
  border-collapse: collapse;

  td, th {
    border: 1px solid #ccc !important;
  }
}
</style>
