import axios from "@/helpers/axios";

const workspace = {
  // create: function (options) {
  //   return axios
  //     .post('project/create/', options)
  //     .then(({ data }) => data);
  // },
  // delete: function (options) {
  //   return axios
  //     .post('project/delete/' + options.id, options)
  //     .then(({data}) => data);
  // },
  // list: function (options) {
  //   return axios
  //     .get('project/list/', options)
  //     .then(({ data }) => data.data);
  // },
  // update: function (options) {
  //   return axios
  //     .post('project/update/' + options.id, options)
  //     .then(({data}) => data.data);
  // },
  getOne: function (options) {
    return axios
      .get('workspace/' + options.id, options)
      .then(({data}) => data);
  },
};

export {workspace};
