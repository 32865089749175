<template>
  <component :is="currentLayoutName" />
</template>

<script>
import DefaultLayout from "@/layouts/Default.vue";
import AuthorizationLayout from "@/layouts/Authorization.vue";
import "@/styles/quasar.scss";

export default {
  components: {
    DefaultLayout,
    AuthorizationLayout,
  },
  computed: {
    currentLayoutName() {
      if (typeof this.$route.href === "string") {
        if (typeof this.$route.meta?.layoutName === "string") {
          return `${this.$route.meta.layoutName}Layout`;
        }

        return "DefaultLayout";
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
html, body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
