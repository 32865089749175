import axios from '@/helpers/axios';

const account = {
  register: function (options) {
    return axios
    .post('auth/register/', options)
    .then(({data}) => data);
  },
  login: function (options) {
    return axios
    .post('auth/login/', options)
    .then(({data}) => data);
  },
  logout: function (options) {
    return axios
    .get('auth/logout/', options)
    .then(({data}) => data.data);
  },
  // current user self info
  user: function (options) {
    return axios
    .get('auth/user/', options)
    .then(({data}) => data);
  },
};

export { account };
