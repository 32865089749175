<template>
  <main>
    <div style="padding: 2em">
      <h4>Ты в Понедельнике</h4>
      <q-separator/>
    </div>
  </main>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
