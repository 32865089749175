<template>
  <div class="q-pa-md">
    <div class="page-title-row">
      <h6 style="margin: 0">Статусы</h6>
      <q-btn size="sm" flat padding="xs md" label="+ статус" @click="showNewStatusPanel" />
    </div>

    <div v-show="newStatusCreationInProgress" class="q-mb-lg">
      <q-card class="my-card">
        <q-card-section>
          <div class="q-gutter-x-md row items-start">
            <q-input
                filled
                dense
                v-model="newStatusName"
                label="Название статуса"
                style="width: calc(100% - 600px);"/>

            <q-select filled
                      dense
                      v-model="newStatusProject"
                      label="Проект"
                      :options="projectList"
                      :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                      :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                      style="width: 190px"/>
            <q-input
                filled
                dense
                v-model="newStatusSort"
                label="Сортировка"
                style="width: 140px;"/>

            <q-btn type="submit" color="primary" label="Создать"
                   style="width: 110px"
                   @click="createStatus()"/>

            <q-btn flat padding="xs" icon="close" @click="closeAddNewItemForm()"/>
          </div>
        </q-card-section>
      </q-card>
    </div>

<!--    <div class="q-py-md">
      <q-select filled
                dense
                v-model="filterTasksByProject"
                label="Фильтр по проекту"
                :options="projectList"
                :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                style="width: 240px"/>
    </div>-->

    <q-table
        :is-loading="isLoading"
        :rows="statusList || []"
        :columns="columns"
        :pagination="{ rowsPerPage: 0 }"
        row-key="name"
        @row-click="onRowClick"
    >
<!--        hide-bottom-->

      <template v-slot:body-cell-action="props">
        <td align="right">
          <q-btn-dropdown dense flat color="grey" dropdown-icon="more_horiz" @click.stop="stopProp">
            <q-list>
              <q-item clickable v-close-popup @click.stop="getOneStatus(props.row.id)">
                <q-item-section avatar>
                  <q-icon name="edit"></q-icon>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Редактировать</q-item-label>
                </q-item-section>
              </q-item>
              <q-separator />
              <q-item clickable v-close-popup @click.stop="deleteStatus(props.row.id)">
                <q-item-section avatar>
                  <q-icon name="delete" color="red"></q-icon>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Delete</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </td>
      </template>
    </q-table>
  </div>

  <q-dialog v-model="fullWidthHeight">
    <status-single :status-data="singleStatusData" />
  </q-dialog>
</template>

<script>
import {status} from "@/api/status";
import {project} from "@/api/project";
import StatusSingle from '@/components/tasks/StatusSingle.vue'

const columns = [
  {name: 'name', required: true, label: 'Статус', align: 'left',
    field: row => row.name,
    format: val => `${val}`,
  },
  {name: 'client', label: 'Проект', align: 'left', field: 'project_id'},
  {name: 'sort', label: 'Сортировка', align: 'left', field: 'sort'},
  {name: "action", label: null, align: "right"},
]

export default {
  name: "Statuses",
  data() {
    return {
      columns,

      statusList: [],
      projectList: [],
      isLoading: true,

      newStatusName: '',
      newStatusProject: '',
      newStatusSort: '',

      newStatusCreationInProgress: false,

      singleStatusData: null,

      fullWidthHeight: false,
    }
  },
  created() {
    this.listStatuses();
    this.listProjects();
  },
  computed: {
  },
  watch: {
  },
  methods: {
    // API
    async listStatuses() {
      try {
        this.isLoading = true;
        this.statusList = [];

        const result = await status.list({
          // project_id: 1,
        });

        this.statusList = result;
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.isLoading = false;
      }
    },
    async listProjects() {
      try {
        this.isLoading = true;
        this.projectList = [];

        const result = await project.list();

        this.projectList = result;
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.isLoading = false;
      }
    },
    async createStatus() {
      try {
        const result = await status.create({
          name: this.newStatusName,
          project_id: this.newStatusProject.id,
          sort: this.newStatusSort,
        });
      } catch (error) {
        if (error.response.status !== 404) {}
      } finally {
        this.listStatuses();
      }
    },
    editStatus(id) {
      this.getOneStatus(id);
    },
    async deleteStatus(id) {
      try {
        console.log('del '+id);
        const result = await status.delete({
          id: id,
        });
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.listStatuses();
      }
    },
    async getOneStatus(id) {
      try {
        const result = await status.getOne({
          id: id,
        });

        this.singleStatusData = result;
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        // this.fullWidthHeight = true;
      }
    },
    showNewStatusPanel() {
      this.newStatusCreationInProgress = true;
    },
    stopProp() {
      return;
    },
  },
}
</script>

<style lang="scss" scoped>
  table svg {
    fill: #ccc;
  }
  .page-title-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .not-draggable {
    cursor: no-drop;
  }
  .list-group {
    min-height: 80px;
    background-color: #e7e7e7;
    border-radius: 4px;
    padding: 10px;
  }

</style>
