<template>
  <div class="q-pa-md">
    <div class="page-title-row">
      <h6 style="margin: 0">Задачи</h6>
    </div>

    <div class="q-py-md">
      <div class="q-gutter-x-md row items-start">
<!--        <q-select filled
                  dense
                  v-model="filterTasksByProject"
                  label="по проекту"
                  :options="projectList"
                  :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                  :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                  style="width: 240px"/>-->

        <q-select filled
                  dense
                  v-model="filterTasksByAssignee"
                  label="Назначена"
                  :options="userList"
                  :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                  :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                  style="width: 240px"/>
      </div>
    </div>

    <q-table
        :is-loading="isLoading"
        :rows="taskList || []"
        :columns="columns"
        :pagination="{rowsPerPage:0}"
        row-key="name"
        wrap-cells
    >
<!--        @row-click="onRowClick"-->

      <template v-slot:body-cell-name="props">
        <q-td :props="props"
              style="width: 50%"
        >
            {{props.value}}
        </q-td>
      </template>

      <template v-slot:body-cell-dueDate="props">
        <q-td :props="props"
              :label="props.value"
              :class="(props.value.indexOf('проср')>=0)?'overdue':''">
          <div>
            <q-badge
                :label="props.value"
                :class="(props.value.indexOf('проср')>=0)?'':'bg-white text-black'"
                :color="(props.value.indexOf('проср')>=0)?'negative':''"
            ></q-badge>
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-status="props">
        <q-td :props="props"
              style="white-space: nowrap">
          {{props.value}}
        </q-td>
      </template>

      <template v-slot:body-cell-action="props">
        <td align="right">
          <q-btn-dropdown dense flat color="grey" dropdown-icon="more_horiz" @click.stop="stopProp">
            <q-list>
              <q-item clickable v-close-popup @click.stop="deleteTask(props.row.id)">
                <q-item-section avatar>
                  <q-icon name="delete" color="red"></q-icon>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Delete</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </td>
      </template>
    </q-table>
  </div>

  <q-dialog v-model="fullWidthHeight">
    <task-single :task-data="singleTaskData" :user-list="userList" :project-list="projectList"/>
  </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {task} from "@/api/task";
import {project} from "@/api/project";
import TaskSingle from "@/components/tasks/TaskSingle.vue";

export default {
  name: "Tasks",
  components: { TaskSingle },
  data() {
    return {
      columns: [
        {
          name: 'name',
          required: true,
          label: 'Задача',
          align: 'left',
          sortable: true,
          field: row => row.name,
          format: val => `${val}`,
        },
        {
          name: 'dueDate',
          label: 'Дата',
          align: 'left',
          sortable: true,
          field: row => row.due_date ?? '',
          // format: (val) => (val ? this.$dayjs(val).valueOf() : "-"),
          // format: (val) => (val ? this.$dayjs(val).format(this.$dayjs.cf.S) : "-"),
          format: (val) => (val
              ? (this.$dayjs().isAfter(this.$dayjs(val))
                      // ? `${this.$dayjs(val).format(this.$dayjs.cf.S)}, просрочка ${this.$dayjs().diff(this.$dayjs(val), 'day')}д`
                      ? `${this.$dayjs(val).format(this.$dayjs.cf.S)}, просрочено ${this.$dayjs().diff(this.$dayjs(val), 'day')}д`
                      : `${this.$dayjs(val).format(this.$dayjs.cf.S)}`
              ) : ''),
          // sort: (a, b, rowA, rowB) => this.$dayjs(a).valueOf() - this.$dayjs(b).valueOf(),
          // sort: (a, b) => parseInt(this.$dayjs(a).valueOf()) - parseInt(this.$dayjs(b).valueOf()),
          sortOrder: 'ad', // or 'da'

        },
        {
          name: 'status',
          label: 'Статус',
          align: 'left',
          sortable: true,
          field: row => row.group.name ?? '',
          format: val => `${val}`,
        },
        {
          name: 'project',
          label: 'Проект',
          align: 'left',
          sortable: true,
          field: row => row.group?.project?.id ?? '',
          format: (val) => {
            return this.getProjectNameById(val)
          },
        },
        // {name: "action", label: null, align: "right"},
      ],

      taskList: [],
      projectList: [],
      isLoading: true,
      singleTaskData: null,

      userList: this.$settings.userList,

      newTaskName: '',
      newTaskProject: '',
      newTaskAssignee: '',

      filterProjectId: null,
      filterAssigneeId: null,

      newTaskCreationInProgress: false,

      fullWidthHeight: false,

      editor: '',

      filterTasks: null,

      filterTasksByProject: null,
      filterTasksByAssignee: null,
    }
  },
  created() {
    this.listProjects();
    this.listTasks();
  },
  mounted: function () {

  },
  computed: {
  },
  watch: {
    filterTasksByProject(val) {
      this.filterProjectId = val.id;
      this.listTasks();
    },
    filterTasksByAssignee(val) {
      this.filterAssigneeId = val.id;
      this.listTasks();
    },
  },
  methods: {
    getProjectNameById(id) {
      if(this.projectList.length === 0) {
        return '—';
      }
      // let project = this.projectList.filter(obj => {
      //   return obj.id === id;
      // })[0];
      // return project.name;

      return "=";
    },
    clearAddNewItemForm() {
      this.newTaskName = '';
      this.newTaskProject = '';
      this.newTaskAssignee = '';
      this.newTaskCreationInProgress = false;
    },
    closeAddNewItemForm() {
      this.newTaskCreationInProgress = false;
    },
    // API
    async listProjects() {
      try {
        this.isLoading = true;
        this.projectList = [];

        const result = await project.list();

        this.projectList = result;
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.isLoading = false;
      }
    },
    async createTask() {
      try {
        // console.log(this.newTaskAssignee.id);
        const result = await task.create({
          name: this.newTaskName,
          project_id: this.newTaskProject.id,
          users: [this.newTaskAssignee.id],
          description: this.editor,
        });
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.clearAddNewItemForm();
        this.listTasks();
      }
    },
    async deleteTask(id) {
      try {
        const result = await task.delete({
          id: id,
        });
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.listTasks();
      }
    },
    async listTasks() {
      try {
        let options = {};
        if(this.filterProjectId) options.project_id = this.filterProjectId;
        if(this.filterAssigneeId) options.assignee = this.filterAssigneeId;

        const result = await task.list(options);
        // todo: эта доп проверка потому что стали вываливаться задачи всех пользователей с бэка. Баг
        // this.notificationsList = result;
        this.taskList = result.filter(obj => { return obj.user_id === this.user.id });

        // this.taskList = result;
      } catch (error) {
        if (error.response?.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.isLoading = false;
      }
    },
    async getOneTask(id) {
      try {
        const result = await task.getOne({
          id: id,
        });

        this.singleTaskData = result;
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.fullWidthHeight = true;
      }
    },
    //
    showNewTaskPanel() {
      this.newTaskCreationInProgress = true;
    },
    onRowClick (evt, row) {
      this.getOneTask(row.id);
    },
    stopProp() {
      return;
    },

    // draggable
    add: function() {
      this.list.push({ name: "Juan" });
    },
    replace: function() {
      this.list = [{ name: "Edgard" }];
    },
    clone: function(el) {
      return {
        name: el.name + " cloned"
      };
    },
    log: function(evt) {
      window.console.log(evt);
    },

    pasteCapture(evt){
      console.log('pasteCapture===' + evt);
    },
    dropCapture(evt) {
      console.log('dropCapture===' + evt);
    }
  },
}
</script>

<style lang="scss" scoped>
  table svg {
    fill: #ccc;
  }
  .page-title-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .not-draggable {
    cursor: no-drop;
  }
  .list-group {
    min-height: 80px;
    background-color: #e7e7e7;
    border-radius: 4px;
    padding: 10px;
  }

</style>
