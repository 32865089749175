import axios from "axios";
// import dayjs from "@/helpers/dayjs";
// import store from "@/store";

const instance = axios.create({
  // baseURL: process.env.VUE_APP_API_URL === 'production' ? process.env.VUE_APP_API_URL : '',
  baseURL: process.env.VUE_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    config.data = {
      meta: {
        // timezone: dayjs.tz.guess(),
      },
      ...config.data,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  (config) => {
    config.data = {
      // accessToken: store.state.account.token,
      ...config.data,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem("minutka-app"))?.token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
