<template>
  <div class="row">
    <div class="col-9">
      <div class="q-pa-md">
        <h5 style="margin-top: 0">Клиенты</h5>

        <q-table
            :rows="clientsList"
            :columns="columns"
            row-key="name"
        />
      </div>
    </div>
    <div class="col-3">
      <div class="q-pa-md">
        <q-card class="my-card">
          <q-card-section>
            <h6 style="margin-top: 0">+ Новый клиент</h6>

            <div class="q-gutter-md" style="max-width: 500px">

              <q-input
                  filled
                  v-model="newClientName"
                  label="Название клиента"
                  lazy-rules
                  :rules="[ val => val && val.length > 0 || 'Please type something']"
              />

              <q-input
                  filled
                  v-model="newClientHourlyCost"
                  label="Стоимость часа"
                  lazy-rules
                  :rules="[ val => val && val.length > 0 || 'Please type something']"
              />

              <div>
                <q-btn type="submit" color="primary" label="Создать"
                       @click="createClient()"/>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import {clients} from "@/api/clients";

const columns = [
  {name: 'name', required: true, label: 'Клиент', align: 'left',
    field: row => row.name,
    format: val => `${val}`,
  },
]

export default {
  name: "Clients",
  setup() {
    return {
      columns,
    }
  },
  data() {
    return {
      clientsList: [],
      newClientName: '',
      newClientHourlyCost: '',
    }
  },
  created() {
    this.fetchClients();
  },
  methods: {
    async fetchClients() {
      try {
        this.clientsList = [];

        const result = await clients.list();

        this.clientsList = result;
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.isLoading = false;
      }
    },
    async createClient() {
      try {
        const result = await clients.create({
          name: this.newClientName,
          hourly_cost: this.newClientHourlyCost,
        });
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.fetchClients();
      }
    },
  },
}
</script>

<style lang="sass" scoped>

</style>
