import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


import 'dayjs/locale/ru';

dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(duration);
dayjs.extend(customParseFormat)
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.locale('ru');

const config = {
  formats: {
    L: 'YYYY-MM-DD',
    LLL: 'DD MMM YYYY HH:mm',
  },
};

// sustom formats
dayjs.cf = {
  S: 'DD MMM'
}

dayjs.updateLocale('ru', config);

export default dayjs;
