<template>
  <div class="q-pa-md">
    <div class="page-title-row">
      <h6 style="margin: 0">{{ project ? project.name : '' }}</h6>

      <q-btn-group flat class="btngroup">
        <q-btn
            flat
            size="sm"
            padding="sm"
            icon="o_list"
            :to="'/projects/'+project.id"
            label="Задачи" />
        <q-btn
            flat
            size="sm"
            padding="sm"
            icon="o_description"
            class="active"
            :to="'/projects/'+project.id+'/doc'"
            label="Документы" />
      </q-btn-group>

      <q-btn size="md"
             padding="xs md"
             label="документ"
             icon="add"
             @click="createDocument"
             style="margin-left: auto"/>
    </div>


    <q-list>
      <q-item
          clickable
          v-ripple
          v-for="doc in documents[0].data"
          :to="'/projects/'+project.id+'/doc/'+doc.id">
        <q-item-section avatar>
          <q-icon color="grey" name="o_description" />
        </q-item-section>

        <q-item-section>{{ doc.name }}</q-item-section>

        <q-item-section side>
          <router-link :to="'/projects/'+project.id+'/doc/'+doc.id+'/edit'">
            <q-btn flat size="sm" padding="sm" icon="o_edit" />
          </router-link>
        </q-item-section>
      </q-item>
    </q-list>


    <q-card v-if="isEmpty" style="font-size: large">
      <q-card-section>
        Документов здесь нет :(
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { project } from '@/api/project'
import { document } from '@/api/document'
import { mapGetters } from 'vuex'
export default {
  name: 'Documents',
  data() {
    return {
      projectId: null,
      project: {},

      documents: ' ',

      isEmpty: null
    }
  },
  computed: {
    ...mapGetters({
      user: "account/user",
    }),
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
        () => this.$route.params,
        () => {
          this.projectId = parseInt(this.$route.params.project_id);
          this.syncProject();
          this.fetchDocuments();
        },
        // fetch the data when the view is created and the data is
        // already being observed
        { immediate: true }
    )
  },
  methods: {
    async syncProject(options) {
      try {
        options = options ?? {};
        options.id = this.projectId;

        const result = await project.getOne(options);

        this.project = result;
      } catch (error) {
        if (error.response?.status !== 404) {}
      } finally {}
    },
    async fetchDocuments(options) {
      try {
        options = options ?? {};
        options.type = 'project';
        options.id = this.projectId;

        const result = await document.list(options);

        if (result[0].data.length) {
          this.documents = result;
        } else {
          this.isEmpty = true
        }
      } catch (error) {
        if (error.response?.status !== 404) {}
      } finally {}
    },
    async createDocument() {
      try {

        const result = await document.create({
          user_id: this.user.id,
          name: 'Без имени',
          body: '...',
          id: this.project.id,
          type: 'project',
        });

        // this.project = result;

        this.$router.push({
          name: 'docSingleEdit',
          params: {
            project_id: this.project.id,
            id: result.id,
          },
        })
      } catch (error) {
        if (error.response?.status !== 404) {}
      } finally {}
    },
  }
}
</script>

<style lang="scss" scoped>
.page-title-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
}

.btngroup {
  .active {
    border-bottom: 2px solid $primary;
  }
}
</style>
