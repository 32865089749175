import axios from "@/helpers/axios";

const document = {
  create: function (options) {
    return axios
    .post('document/create/', options)
    .then(({ data }) => data);
  },
  delete: function (options) {
    return axios
    .post('document/delete/' + options.id, options)
    .then(({data}) => data);
  },
  list: function (options) {
    let addGetParams = '';
    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        if( addGetParams == '' ) addGetParams += '?';
        else addGetParams += '&';
        addGetParams += `${key}=${options[key]}`;
      }
    }
    return axios
      .get('document/list/' + addGetParams, options)
      .then(({ data }) => data);
  },
  update: function (options) {
    return axios
    .post('document/update/' + options.id, options)
    .then(({data}) => data);  // TODO data пропала
  },
  getOne: function (options) {
    return axios
      .get('document/' + options.id, options)
      .then(({ data }) => data);
  },
};

export {document};
