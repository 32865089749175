<template>
  <div class="q-pa-md row items-center justify-center" style="height: 100vh">
    <div style="max-width: 400px" class="col-12">
      <div class="text-h6 text-center q-py-md">Регистрация В Понедельнике</div>

      <q-banner v-if="isRegistered" inline-actions rounded class="bg-orange text-white">
        <span style="font-size: 16px">Теперь у вас есть аккаунт</span>

        <template v-slot:action>
          <q-btn flat label="Войти" to="/login" />
        </template>
      </q-banner>

      <q-form v-if="!isRegistered"  @submit="onSubmit" class="full-width" greedy>
        <q-input
          v-model="name"
          label="Имя"
          class="q-mb-md"
          lazy-rules="ondemand"
          autocomplete="onff"
          :rules="[
            (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
          ]"
        />
        <q-input
          v-model="email"
          label="E-mail"
          class="q-mb-md"
          lazy-rules="ondemand"
          autocomplete="onff"
          :rules="[
            (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
          ]"
        />

        <q-input
          v-model="password"
          label="Пароль"
          :type="isPasswordVisible ? 'text' : 'password'"
          class="q-mb-md"
          lazy-rules="ondemand"
          autocomplete="onff"
          :rules="[
            (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
          ]"
        >
          <template v-slot:append>
            <q-icon
              :name="isPasswordVisible ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPasswordVisible = !isPasswordVisible"
            />
          </template>
        </q-input>

        <div v-if="error" class="text-red q-mb-md">
          <q-icon name="warning" /> Неверно введен пароль или логин
        </div>

        <div v-if="$route.params?.['token-error']" class="text-red q-mb-md">
          <q-icon name="warning" /> Ошибка авторизации через токен
        </div>

        <div class="q-mb-md">
          <q-btn
            :disable="isLoading"
            label="Создать аккаунт"
            type="submit"
            color="primary"
            no-caps
            unelevated
            class="full-width"
          />
        </div>

        <div class="q-mb-md">
          <q-btn
              label="Уже есть аккаунт?"
              to="/login"
              color="grey-7"
              flat
              no-caps
              unelevated
              class="full-width"
          />
        </div>
      </q-form>
    </div>
  </div>
  {{fff}}
</template>

<script>
import { account } from '@/api/account'

export default {
  data() {
    return {
      name: null,
      email: null,
      password: null,
      isPasswordVisible: false,
      isLoading: false,
      isRegistered: false,
      error: null,

      fff: null,
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.isLoading = true;

        const register = await account.register({
          name: this.name,
          email: this.email,
          password: this.password,
          confirm_password: this.password,
        });

        if(register.accessToken) {
          this.isRegistered = true;
        }

      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
