import axios from "@/helpers/axios";

const user = {
  create: function (options) {
    return axios
        .post('user/create/', options)
        .then(({data}) => data);
  },
  delete: function (options) {
    return axios
        .post('user/delete/' + options.id, options)
        .then(({data}) => data);
  },
  list: function (options) {
    return axios
        .get('user/list/', options)
        .then(({data}) => data.data);
  },
  update: function (options) {
    return axios
        .post('user/update/' + options.id, options)
        .then(({data}) => data.data);
  },
  getOne: function (options) {
    return axios
        .get('user/' + options.id, options)
        .then(({data}) => data);
  },
};

export {user};
