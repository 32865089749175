import axios from "@/helpers/axios";

const comment = {
  create: function (options) {
    return axios
        .post('comment/create/', options)
        .then(({data}) => data);
  },
  delete: function (options) {
    return axios
        .post('comment/delete/' + options.id, options)
        .then(({data}) => data);
  },
  list: function (options) {
    return axios
        .get('comment/list/', options)
        .then(({data}) => data.data);
  },
  update: function (options) {
    return axios
        .post('comment/update/' + options.id, options)
        .then(({data}) => data.data);
  },
  getOne: function (options) {
    return axios
        .get('comment/' + options.id, options)
        .then(({data}) => data);
  },
};

export {comment};
