import axios from "@/helpers/axios";

const status = {
  create: function (options) {
    return axios
      .post('group/create/', options)
      .then(({ data }) => data);
  },
  delete: function (options) {
    return axios
    .post('group/delete/' + options.id, options)
      .then(({ data }) => data);
  },
  list: function (options) {
    let addGetParams = '';
    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        if( addGetParams == '' ) addGetParams += '?';
        else addGetParams += '&';
        addGetParams += `${key}=${options[key]}`;
      }
    }
    return axios
    .get('group/list/' + addGetParams, options)
    .then(({data}) => data.data);
  },
  update: function (options) {
    return axios
    .post('group/update/' + options.id, options)
    .then(({data}) => data.data);
  },
  getOne: function (options) {
    return axios
    .get('group/' + options.id, options)
    .then(({data}) => data);
  },
};

export {status};
