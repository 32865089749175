const duration = {
    // при вводе значения в инпут
    stringToMinutes(durationStr) {
        const reNum = /\d+/g;
        const weeksStr =   (durationStr.match(/[0-9]+[нHwW]/g));
        const daysStr =    (durationStr.match(/[0-9]+[дДdD]/g));
        const hoursStr =   (durationStr.match(/[0-9]+[чЧhH]/g));
        const minutesStr = (durationStr.match(/[0-9]+[мМmM]/g));
        const weeks =   weeksStr ? weeksStr[0].match(reNum) : 0;
        const days =    daysStr ? daysStr[0].match(reNum) : 0;
        const hours =   hoursStr ? hoursStr[0].match(reNum) : 0;
        const minutes = minutesStr ? minutesStr[0].match(reNum) : 0;

        const weeksInMinutes = parseInt(weeks) * 7 * 24 * 60;
        const daysInMinutes =  parseInt(days) * 24 * 60;
        const hoursInMinutes = parseInt(hours) * 60;

        return weeksInMinutes + daysInMinutes + hoursInMinutes + parseInt(minutes);
    },
    // при прорисовке таблицы
    minutesToString: function(duration) {
        let durationStr = '';

        duration = parseInt(duration);

        let
            workHours = 8,
            weekMinutes = 7 * workHours * 60,
            dayMinutes =  workHours * 60,
            hourMinutes = 60,
            weeks = 0,
            days = 0,
            hours = 0,
            minutes = 0;

        weeks = Math.floor(duration / weekMinutes);
        days =  Math.floor((duration - weeks * weekMinutes) / dayMinutes);
        hours = Math.floor((duration - weeks * weekMinutes - days * dayMinutes) / hourMinutes);
        minutes = duration - weeks * weekMinutes - days * dayMinutes - hours * hourMinutes;

        if(weeks) durationStr += weeks + 'н ';
        if(days) durationStr += days + 'д ';
        if(hours) durationStr += hours + 'ч ';
        if(minutes) durationStr += minutes + 'м';

        return durationStr;
    },
}

export {duration};
