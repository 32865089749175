import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Notifications from "../views/Notifications.vue";
import Timer from "../views/Timer.vue";
import Projects from "../views/Projects.vue";
import Project from "../views/Project.vue";
import Clients from "../views/Clients.vue";
import Tasks from "../views/Tasks.vue";
import Tags from "../views/Tags.vue";
import Login from "../views/Login.vue";
import Statuses from '@/views/Statuses.vue'
import Register from '@/views/Register.vue'
import Documents from '@/views/Documents.vue'
import Document from '@/views/Document.vue'
import DocumentEdit from '@/views/DocumentEdit.vue'

const baseUrl = '/';

const router = createRouter({
  // base: process.env.VUE_APP_ROUTER_BASE_URL,
  base: baseUrl,
  // history: createWebHistory(process.env.VUE_APP_ROUTER_BASE_URL),
  history: createWebHistory(baseUrl),
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { needAuth: true },
    },
    {
      path: "/notifications",
      name: "notifications",
      component: Notifications,
      meta: { needAuth: true, layoutName: "Default" },
    },
    {
      path: "/timer",
      name: "timer",
      component: Timer,
      meta: { needAuth: true, layoutName: "Default" },
    },
    {
      path: "/projects",
      name: "projects",
      component: Projects,
      meta: { needAuth: true, layoutName: "Default" },
    },
    {
      path: "/projects/:id",
      name: "project",
      component: Project,
      meta: { needAuth: true, layoutName: "Default" },
    },
    {
      path: "/projects/:project_id/doc",
      name: "documents",
      meta: { needAuth: true, layoutName: "Default" },
      children: [
        { path: '', component: Documents },
        { path: ':id', component: Document, name: 'docSingle' },
        { path: ':id/edit', component: DocumentEdit, name: 'docSingleEdit' },
      ],
    },
    {
      path: "/clients",
      name: "clients",
      component: Clients,
      meta: { needAuth: true, layoutName: "Default" },
    },
    // {
    //   path: "/team",
    //   name: "team",
    //   component: Team,
    //   meta: { needAuth: true, layoutName: "Default" },
    // },
    {
      path: "/tasks",
      name: "tasks",
      component: Tasks,
      meta: { needAuth: true, layoutName: "Default" },
    },
    {
      path: "/statuses",
      name: "statuses",
      component: Statuses,
      meta: { needAuth: true, layoutName: "Default" },
    },
    {
      path: "/tags",
      name: "tags",
      component: Tags,
      meta: { needAuth: true, layoutName: "Default" },
    },
    // {
    //   path: "/bills",
    //   name: "bills",
    //   component: Bills,
    //   meta: { needAuth: true, layoutName: "Default" },
    // },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: { needAuth: false, layoutName: "Authorization" },
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      meta: { needAuth: false, layoutName: "Authorization" },
    },
    // {
    //   path: "/alt",
    //   name: "alt",
    //   // component: AlternativeHome,
    // },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = "В Понедельник";
  const isAuthorized = JSON.parse(localStorage.getItem("minutka-app"))?.token;

  if (to.meta.needAuth && !isAuthorized) {
    next({ name: "login" });
  } else next();
  // next();
});

export default router;
