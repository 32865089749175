import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import settings from '@/plugins/settings'
import store from '@/store'
import dayjs from '@/plugins/dayjs';
// import CKEditor from '@ckeditor/ckeditor5-vue';

import 'quasar/dist/quasar.sass'

import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(router)
  .use(settings)
  .use(store)
  .use(dayjs)
  // .use(CKEditor)
  .mount('#app')
