import axios from "@/helpers/axios";

const work = {
  create: function (options) {
    return axios
        .post('work/create/', options)
        .then(({data}) => data);
  },
  delete: function (options) {
    return axios
        .post('work/delete/' + options.id, options)
        .then(({data}) => data);
  },
  list: function (options) {
    // return axios
    //     .get('work/list/', options)
    //     .then(({data}) => data.data);
    //
    let addGetParams = '';
    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        if( addGetParams == '' ) addGetParams += '?';
        else addGetParams += '&';
        addGetParams += `${key}=${options[key]}`;
      }
    }
    return axios
      .get('work/list/' + addGetParams, options)
      .then(({data}) => data.data);
  },
  update: function (options) {
    return axios
        .post('work/update/' + options.id, options)
        .then(({data}) => data.data);
  },
  getOne: function (options) {
    return axios
        .get('work/' + options.id, options)
        .then(({data}) => data);
  },
};

export {work};
