<template>
  <q-card style="width: 1000px; max-width: 80vw;">
    <q-card-section>
<!--      <div class="text-h6">{{ projectData.name ? projectData.name : '' }}</div>-->
    </q-card-section>

    <q-card-section class="q-pt-none q-gutter-md">
      <q-input
          filled
          dense
          v-model="projectData.name"
          style="width: calc(100% - 600px);"/>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="закрыть" v-close-popup />
      <q-btn flat label="сохранить" @click="updateProject" />
    </q-card-actions>

    <q-card-section class="q-pt-none">
<!--      <pre>{{ projectData }}</pre>-->
    </q-card-section>

  </q-card>
</template>

<script>
import {project} from "@/api/project";

export default {
  name: "ProjectSingle",
  props: {
    projectData: null,
    // TODO: так не делать — перезагрузка списка в основном окне
    listProjects: function (){},
  },
  data() {
    return {
      projectName: this.projectData.name,
      taskAssignee: null,
      taskProject: null,
    }
  },
  mounted() {
  },
  methods: {
    async updateProject(options) {
      try {
        const result = await project.update({
          id: this.projectData.id,
          name: this.projectData.name,
        });
      } catch (error) {
        if (error.response.status !== 404) {}
      } finally {
        this.listProjects();
      }
    },
  }
}
</script>

<style scoped>

</style>
