<template>
  <div class="row">
    <div class="col-9">
      <div class="q-pa-md">
        <h5 style="margin-top: 0">Проекты</h5>

        <q-table
            :is-loading="isLoading"
            :rows="projectList || []"
            :columns="columns"
            :pagination="{rowsPerPage:0}"
            row-key="name"
            @row-click="onRowClick"
        >
          <template v-slot:body-cell-action="props">
            <td align="right">
              <q-btn-dropdown dense flat color="grey" dropdown-icon="more_horiz" @click.stop="stopProp">
                <q-list>
                  <q-item clickable v-close-popup @click.stop="editProject(props.row.id)">
                    <q-item-section avatar>
                      <q-icon name="edit"></q-icon>
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Редактировать</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-separator />
                  <q-item clickable v-close-popup @click.stop="deleteProject(props.row.id)">
                    <q-item-section avatar>
                      <q-icon name="delete" color="red"></q-icon>
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Удалить</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </td>
          </template>
        </q-table>

      </div>
    </div>
    <div class="col-3">
      <div class="q-pa-md">
        <q-card class="my-card">
          <q-card-section>
            <h6 style="margin-top: 0">+ Новый проект</h6>

            <div class="q-gutter-md" style="max-width: 500px">

              <q-input
                  filled
                  v-model="newProjectName"
                  label="Название проекта"
                  lazy-rules
                  :rules="[ val => val && val.length > 0 || 'Please type something']"
              />

<!--              <q-select filled
                        v-model="clientForNewProject"
                        label="Клиент"
                        :options="clientsList"
                        :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                        :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"/>-->

              <div>
                <q-btn type="submit" color="primary" label="Создать"
                       @click="createProject()"/>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>

  <q-dialog v-model="fullWidthHeight">
    <project-single :project-data="singleProjectData" :list-projects="fetchProjects" />
  </q-dialog>
</template>

<script>
import {project} from "@/api/project";
import {clients} from "@/api/clients";
import ProjectSingle from "@/components/tasks/ProjectSingle.vue";
import { status } from '@/api/status'

const columns = [
  {name: 'name', required: true, label: 'Проект', align: 'left',
    field: row => row.name,
    format: val => `${val}`,
  },
  {name: 'client', label: 'Клиент', align: 'left', field: 'client_name'},
  {name: 'timeTotal', label: 'Время', align: 'left', field: 'time_total'},
  {name: "action", label: null, align: "right"},
]

export default {
  name: "Projects",
  components: {ProjectSingle},
  setup() {
    return {
      columns,
    }
  },
  data() {
    return {
      projectList: [],
      clientsList: [],
      isLoading: true,

      newProjectName: '',
      clientForNewProject: '',
      // projectOwner: '',
      // projectColor: '',
      // acceptTerms: '',

      singleProjectData: null,

      fullWidthHeight: false,
    }
  },
  created() {
    this.fetchProjects();
    this.fetchClients();
  },
  methods: {
    async fetchProjects() {
      try {
        const result = await project.list();

        this.projectList = result;
      } catch (error) {
        if (error.response.status !== 404) {}
      } finally {}
    },
    async fetchClients() {
      try {
        this.clientsList = await clients.list();
      } catch (error) {
        if (error.response.status !== 404) {}
      } finally {}
    },
    async createProject() {
      try {
        const result = await project.create({
          name: this.newProjectName,
          client_id: this.clientForNewProject.id,
          client_name: this.clientForNewProject.name  // это нужно только для локального апи
        });
      } catch (error) {
        if (error.response.status !== 404) {}
      } finally {
        this.fetchProjects();
      }
    },
    async getOneProject(id) {
      try {
        const result = await project.getOne({
          id: id,
        });

        this.singleProjectData = result;
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.fullWidthHeight = true;
      }
    },
    onRowClick (evt, row) {
      this.getOneProject(row.id);
    },
    editProject(id) {
      this.getOneProject(id);
    },
    async deleteProject (id) {
      try {
        const result = await project.delete({
          id: id,
        });
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.fetchProjects();
      }
    },
    stopProp() {
      return;
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
