<template>
  <div class="q-pa-md">
    <div class="page-title-row">
      <h6 style="margin: 0">{{ project ? project.name : '' }}</h6>

      <q-btn-group flat class="btngroup">
        <q-btn
            flat
            size="sm"
            padding="sm"
            icon="o_list"
            class="active"
            :to="'/projects/'+project.id"
            label="Задачи" />
        <q-btn
            flat
            size="sm"
            padding="sm"
            icon="o_description"
            :to="'/projects/'+project.id+'/doc'"
            label="Документы" />
      </q-btn-group>

<!--      <q-input
          filled
          dense
          v-model="filterString"
          label="Фильтровать задачи"
          style="width: 330px;"/>-->

      <q-btn size="md"
             padding="xs md"
             label="задача"
             icon="add"
             @click="showNewTaskPanel"
             style="margin-left: auto"/>

      <!--      <q-btn
                flat
                round
                size="sm"
                icon="o_settings"
                color="grey"
                :to="'/projects/'+project.id+'/settings'"/>-->
    </div>
    <div v-show="newTaskCreationInProgress" class="q-mb-lg">
      <q-card class="my-card">
        <q-card-section>
          <div class="q-gutter-x-md row items-start">
<!--            <span v-if="project.settings && JSON.parse(project.settings).prefix">{{JSON.parse(project.settings).prefix}}</span>
            <span v-if="project.settings && JSON.parse(project.settings).task_id_counter">{{JSON.parse(project.settings).task_id_counter}}</span>-->

            <q-input
                ref="inputNewTaskName"
                filled
                dense
                v-model="newTaskName"
                label="Название задачи"
                style="width: calc(100% - 540px);"/>

            <q-select filled
                      dense
                      v-model="newTaskStatusId"
                      label="Статус"
                      :options="statuses"
                      :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                      :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                      style="width: 150px"/>

            <q-select filled
                      dense
                      v-model="newTaskAssignee"
                      label="Назначена"
                      :options="userListFunc()"
                      :option-value="opt => Object(opt) === opt && 'id' in opt ? opt.id : null"
                      :option-label="opt => Object(opt) === opt && 'name' in opt ? opt.name : null"
                      style="width: 160px">
              <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps">
                  <q-item-section avatar>
                    <div
                        style="background-color: #ccc; width: 24px; height: 24px; border-radius: 30px; display: flex; justify-content: center; align-items: center;">
                      <q-img
                          v-if="userList.filter(obj => { return obj.id === scope.opt.id })[0]?.avatar"
                          :src="userList.filter(obj => { return obj.id === scope.opt.id })[0]?.avatar"
                          spinner-color="white"
                          style="width: 24px; height: 24px; border-radius: 100%;"
                      >
                      </q-img>
                      <span
                          v-else>{{userList.filter(obj => { return obj.id === scope.opt.id })[0]?.name.split(' ').map(word => word.charAt(0)).join('')}}</span>
                    </div>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ scope.opt.name }}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>

            <q-btn type="submit" color="primary" label="Создать"
                   :disabled="(newTaskName === '')"
                   style="width: 110px"
                   @click="createTask()"/>

            <q-btn flat padding="xs" icon="close" @click="closeAddNewItemForm()"/>

            <ckeditor :editor="editor" v-model="newTaskDescription" :config="editorConfig"></ckeditor>
<!--            @paste.native="evt => pasteCapture(evt)"-->
<!--            @drop.native="evt => dropCapture(evt)"-->
          </div>
        </q-card-section>
      </q-card>
    </div>

<!--    !!!!!!!!!!!!!!!!  ВИД СПИСОК  !!!!!!!!!!!!!!!!

    <q-table
        :is-loading="isLoading"
        :rows="taskList || []"
        :columns="columns"
        :pagination="{ page: 1, rowsPerPage: 0 }"
        hide-bottom
        row-key=name
        @row-click="onRowClick"
    >

      <template v-slot:body-cell-action="props">
        <td align="right">
          <q-btn-dropdown dense flat color="grey" dropdown-icon="more_horiz" @click.stop="stopProp">
            <q-list>
              <q-item clickable v-close-popup @click.stop="getOneTask(props.row.id)">
                <q-item-section avatar>
                  <q-icon name="edit"></q-icon>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Редактировать</q-item-label>
                </q-item-section>
              </q-item>
              <q-separator />
              <q-item clickable v-close-popup @click.stop="deleteTask(props.row.id)">
                <q-item-section avatar>
                  <q-icon name="delete" color="red"></q-icon>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Delete</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </td>
      </template>
    </q-table>-->

  </div>

  <q-dialog v-model="fullWidthHeight" transition-show="fade" transition-hide="fade" transition-duration="200">
    <task-single
        :task="singleTaskData"
        :user-list="userList"
        :project-list="projectList"
        :status-list="statuses"
        @task-updated="taskUpdated"
        @notifications-mark-read="notificationsMarkReadByTask"
    />
  </q-dialog>

  <div class="q-pa-md" style="padding-top: 0">
    <div class="list-container">
      <draggable
          class="list-draggable"
          ghost-class="ghost"
          :list="statuses"
          group=board
          itemKey=name
      >
        <template #item="{ element, index }">
          <div class="list-wrapper">
            <BoardColumn
                :list="statuses[index]"
                @edit-single-task="testEmit"
                @reorder-ends=""
                @btn-new-task-click="showNewTaskPanelFromBoardGroup"
            />
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable/src/vuedraggable";
import {task} from "@/api/task";
import {project} from "@/api/project";
import TaskSingle from "@/components/tasks/TaskSingle.vue";
import BoardColumn from "@/components/project/BoardColumn.vue";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { ImageInsert } from '@ckeditor/ckeditor5-image';

const columns = [
  {name: 'name', required: true, label: 'Задача', align: 'left',
    field: row => row.name,
    format: val => `${val}`,
  },
  {name: 'status', label: 'Статус', align: 'left', field: 'group_name'},
  {name: 'assignee', label: 'Назначена', align: 'left', field: 'assignee_name'},
  // {name: 'estimate_minutes', label: 'Оценка', align: 'left', field: 'estimate_minutes'},
  {name: "action", label: null, align: "right"},
]

export default {
  name: "Project",
  components: {
    BoardColumn,
    TaskSingle,
    draggable,
    // ckeditor: CKEditor.component,
  },
  data() {
    return {
      projectId: null,
      project: {},
      projectSettings: null, // todo: задается из projectList и syncProject, а надо бы из одного места

      columns,

      taskList: [],
      projectList: [],
      isLoading: true,
      singleTaskData: null,

      // userList: [],
      // userListFiltered: [],
      userList: this.$settings.userList,
      // userList: this.$settings.userList.filter(obj => {
      //   if (this.user?.allow?.users?.allowIds?.includes(obj.id)) {
      //     return obj.id;
      //   }
      // }),


      // справочник: все списки которые пришли в data со списками задач
      statuses: [],

      newTaskName: '',
      newTaskStatusId: '',
      newTaskAssignee: '',
      newTaskDescription: '',

      newTaskCreationInProgress: false,

      fullWidthHeight: false,

      editor: ClassicEditor,
      editorConfig: {
        // plugins: [ /* ... */ , ImageInsert ],
        // toolbar: [ /* ... */ , 'insertImage' ]
      },

      filterString: null,
    }
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
        () => this.$route.params,
        () => {
          this.projectId = parseInt(this.$route.params.id);
          this.syncProject();
          this.listProjects().then(()=>{
            this.project = this.projectList.filter(obj => {
              return obj.id === this.projectId;
              if(this.project.settings) {
                this.projectSettings = JSON.encode(this.project.settings);
              }
            })[0];
          });
          this.clearAddNewItemForm();
          this.closeAddNewItemForm();
        },
        // fetch the data when the view is created and the data is
        // already being observed
        { immediate: true }
    )
  },
  updated() {
  },
  computed: {
    ...mapGetters({
      user: "account/user",
    }),
  },
  watch: {
    newTaskCreationInProgress(val) {
      if(val) {
        this.$nextTick(() => {
          this.$refs.inputNewTaskName.focus();
        });
      }
    },
  },
  methods: {
    userListFunc() {
      let filteredUsers;

      if(this.user?.settings?.allow?.users?.allowIds) {
        filteredUsers = this.$settings.userList.filter(obj => {
          if (this.user.settings.allow?.users?.allowIds?.includes(obj.id)) {
            return obj.id;
          }
        });
      } else {
        filteredUsers = this.userList;
      }

      return filteredUsers;
    },
    clearAddNewItemForm() {
      this.newTaskName = '';
      this.newTaskAssignee = '';
      this.newTaskDescription = '';
      this.newTaskCreationInProgress = false;
    },
    closeAddNewItemForm() {
      this.newTaskCreationInProgress = false;
    },
    // API
    async listProjects() {
      try {
        const result = await project.list(this.projectId);

        this.projectList = result;
      } catch (error) {
        if (error.response?.status !== 404) {}
      } finally {}
    },
    async createTask() {
      // инкремент таск айди
      // let projectSettings = {};

      // if(this.project.settings) {
      //   projectSettings = JSON.parse(this.project.settings);
      // }

      if(this.projectSettings.task_id_counter >= 0) {
        this.projectSettings.task_id_counter += 1;
      } else {
        console.log("NONONONONONONONONO")
        this.projectSettings.task_id_counter = 1;
      }

      //

      try {
        let options = {
          name: this.newTaskName,
          project_id: this.projectId,
          users: this.newTaskAssignee.id,
          // group_id: this.newTaskStatusId.id,
          assignee: this.newTaskAssignee.id,
          description: this.newTaskDescription,
          // settings: '{"task_id":'+taskId+'}',
        };
        options.group_id = this.newTaskStatusId ? this.newTaskStatusId.id : null;
        const result = await task.create(options);

      } catch (error) {
        if (error.response?.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.clearAddNewItemForm();
        this.updateProjectSettings();
        // this.syncProject();
      }
    },
    async deleteTask(id) {
      try {
        const result = await task.delete({
          id: id,
        });
      } catch (error) {
        if (error.response.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.syncProject();
      }
    },
    async syncProject(options) {
      try {
        options = options ?? {};
        options.id = this.projectId;

        const result = await project.getOne(options);

        if(result.settings) {
          console.log(JSON.parse(result.settings).task_id_counter)
          this.projectSettings = JSON.parse(result.settings);
        }

        if(result.groups.length) {
          // TODO: вынести из загрузки
          result.groups.forEach((group) => {
            group.tasks.forEach((task) => {
              if (task.group_id) {
                task.group_name = group.name;
              }
              if (task.assignee) {
                task.assignee_name = this.getUserNameById(task.assignee);
              }
            });
          });

          this.statuses = result.groups;

          this.statuses.forEach(function (item) {
            if (item.tasks) {
              item.tasks.sort((a, b) => a.sort - b.sort);
            }
          });
          this.taskList = result.groups[0].tasks;
        } else {
          this.statuses = null;
        }
      } catch (error) {
        if (error.response?.status !== 404) {}
      } finally {}
    },
    async getOneTask(id) {
      try {
        const result = await task.getOne({
          id: id,
        });

        this.singleTaskData = result;
      } catch (error) {
        if (error.response?.status !== 404) {
          // this.$settings.showErrorMessage();
        }
      } finally {
        this.fullWidthHeight = true;
      }
    },
    //
    async showNewTaskPanel() {
      this.newTaskStatusId = null;
      this.newTaskCreationInProgress = true;
      // todo: копипаст префикс задачи проставить в название
      if (this.projectSettings &&
          this.projectSettings.prefix &&
          this.projectSettings.task_id_counter >= 0
      ) {
        let newTaskId = this.projectSettings.task_id_counter + 1;
        this.newTaskName = this.projectSettings.prefix + '-' + newTaskId + ' ';
      }
    },
    showNewTaskPanelFromBoardGroup(id) {
      // this.newTaskStatusId = id;
      this.newTaskStatusId = this.statuses.filter(obj => {
        return obj.id === id;
      })[0];
      this.newTaskCreationInProgress = true;
      // todo: копипаст префикс задачи проставить в название
      if (this.projectSettings &&
          this.projectSettings.prefix &&
          this.projectSettings.task_id_counter >= 0
      ) {
        let newTaskId = this.projectSettings.task_id_counter + 1;
        this.newTaskName = this.projectSettings.prefix + '-' + newTaskId + ' ';
      }
    },
    onRowClick (evt, row) {
      this.getOneTask(row.id);
    },
    stopProp() {
      return;
    },
    // helpers
    getStatusNameById(id) {
      if(this.statuses.length === 0) {
        return '—';
      }
      let status = this.statuses.filter(obj => {
        return obj.id === id;
      })[0];

      if(status.name)
        return status.name;
      else
        return '—';
    },
    getUserNameById(id) {
      if(this.userList.length === 0) {
        return '—';
      }
      let user = this.userList.filter(obj => {
        return obj.id === id;
      })[0];

      if(user.name)
        return user.name;
      else
        return '—';
    },
    testEmit(element) {
      this.getOneTask(element.id);
    },
    taskUpdated() {
      this.syncProject();
      this.fullWidthHeight = false; // так закроем диалог
    },
    notificationsMarkReadByTask() {
      // не синковать проект а убирать пометку, чтобы не перезапрашивать данные
      this.syncProject();
    },
    // копипаст из Projects.vue
    async updateProjectSettings() {
      try {
        const result = await project.update({
          id: this.projectId,
          settings: JSON.stringify(this.projectSettings),
        });
      } catch (error) {
        if (error.response?.status !== 404) {}
      } finally {
        this.syncProject();
      }
    },
  },
}
</script>

<style lang="scss" scoped>
table svg {
  fill: #ccc;
}

.page-title-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
}

.list-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px;
  z-index: 10;
}

.list-draggable {
  width: 100%;
}

.list-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex: 1 1 300px;
  //width: 100%;
  height: calc(100vh - 100px);
  //padding: 20px;
  //gap: 20px;
  //overflow-x: scroll;
  overflow-y: hidden;
  //border:1px solid red;
}

.list-draggable {
  display: flex;
  gap: 20px;
}

.btngroup {
  .active {
    border-bottom: 2px solid $primary;
  }
}
</style>
