const account = {
  namespaced: true,
  state: {
    user: null,
    token: null,
    userList: [],
    ...JSON.parse(localStorage.getItem('minutka-app')),
  },
  mutations: {
    setUser(state, payload) {
      if(payload.user?.settings) {
        payload.user.settings = JSON.parse(payload.user.settings) ?? {};
      }
      state.user = payload.user;
      localStorage.setItem('minutka-app', JSON.stringify(state));
    },
    setToken(state, payload) {
      state.token = payload.token;
      localStorage.setItem('minutka-app', JSON.stringify(state));
    },
  },
  actions: {
    logout({ commit }) {
      commit('setUser', { user: null });
      commit('setToken', { token: null });
    },
  },
  getters: {
    // extension: (state) => state.extension,
    // phone: (state) => state.phone,
    user: (state) => state.user,
    token: (state) => state.token,
    userList: (state) => state.userList,
  },
};

export { account };
