<template>
  <div class="row">
    <div class="col-12">
      <div class="q-pa-md">
        <h5 style="margin-top: 0">Входящие</h5>

        <q-table
            :is-loading="isLoading"
            :rows="notificationsList || []"
            :columns="columns"
            :pagination="{rowsPerPage:0}"
            row-key="name"
        >
        </q-table>
      </div>
    </div>
  </div>
</template>

<script>
import {notification} from "@/api/notification";
import { mapGetters } from 'vuex';
export default {
  name: 'Notifications',
  computed: {
    ...mapGetters({
      user: "account/user",
    }),
  },
  setup() {
    return {
    }
  },
  data() {
    return {
      notificationsList: [],
      columns: [
        {name: 'event_type', required: true, label: 'Тип', align: 'left', field: row => row.event_type},
        {name: 'model_name', required: true, label: 'Модель', align: 'left', field: row => row.model_name},
        {name: 'id', required: true, label: 'id', align: 'left', field: row => row.id},
        {name: 'user_id', required: true, label: 'User id', align: 'left', field: row => row.user_id},
        {name: 'description', required: true, label: '', align: 'left', field: row => row.description},
        {name: 'is_new', required: true, label: 'Новый', align: 'left', field: row => row.is_new},
      ],
    }
  },
  created() {
    this.fetchNotifications();
  },
  methods: {
    async fetchNotifications() {
      try {
        this.isLoading = true;
        this.notificationsList = [];

        let result = await notification.list({
          user_id: this.user.id,
        });

        // todo: эта доп проверка потому что стали вываливаться уведомления всех пользователей с бэка. Баг
        // this.notificationsList = result;
        this.notificationsList = result.filter(obj => { return obj.user_id === this.user.id });

        this.countNew();
      } catch (error) {
        if (error) {
          console.log('ERROR');
        }
      } finally {
        this.isLoading = false;
      }
    },
    countNew() {
      const initialValue = 0;
      const unreadCount = this.notificationsList.reduce(
          (accumulator, currentValue) => {
            if(currentValue.is_new) {
              accumulator++;
            }
            return accumulator;
          },
          initialValue,
      );

      this.user.unreadCount = unreadCount;
      // console.log(unreadCount);
    }
  },
}
</script>

<style scoped>

</style>
