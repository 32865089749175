<template>
  <div class="q-pa-md row items-center justify-center" style="height: 100vh">
    <div style="max-width: 400px" class="col-12">
      <div class="text-h6 text-center q-py-md">Заглянуть В Понедельник</div>
      <q-form @submit="onSubmit" class="full-width" greedy>
        <q-input
          v-model="login"
          label="E-mail"
          class="q-mb-md"
          lazy-rules="ondemand"
          autocomplete="on"
          :rules="[
            (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
          ]"
        />

        <q-input
          v-model="password"
          label="Пароль"
          :type="isPasswordVisible ? 'text' : 'password'"
          class="q-mb-md"
          lazy-rules="ondemand"
          autocomplete="on"
          :rules="[
            (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
          ]"
        >
          <template v-slot:append>
            <q-icon
              :name="isPasswordVisible ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPasswordVisible = !isPasswordVisible"
            />
          </template>
        </q-input>

        <div v-if="error" class="text-red q-mb-md">
          <q-icon name="warning" /> Неверно введен пароль или логин
        </div>

        <div v-if="$route.params?.['token-error']" class="text-red q-mb-md">
          <q-icon name="warning" /> Ошибка авторизации через токен
        </div>

        <div class="q-mb-md">
          <q-btn
            :disable="isLoading"
            label="Войти"
            type="submit"
            color="primary"
            no-caps
            unelevated
            class="full-width"
          />
        </div>

<!--        <div class="q-mb-md">
          <q-btn
              label="Нет аккаунта? Давай создадим"
              to="/register"
              color="grey-7"
              flat
              no-caps
              unelevated
              class="full-width"
          />
        </div>-->
      </q-form>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { account } from '@/api/account'
import { user } from '@/api/user'

export default {
  data() {
    return {
      login: null,
      password: null,
      isPasswordVisible: false,
      isLoading: false,
      error: null,
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.isLoading = true;

        const result = await account.login({
          email: this.login,
          password: this.password,
        });

        if(result.accessToken) {
          this.setToken({token: result.accessToken});

          const userinfo = await account.user({});

          this.setUser({
            // user: this.$settings.userList.filter(obj => {
            //   return obj.email === this.login
            // })[0],

            user: userinfo,
          });

          this.$router.push({ name: "home" });
        }

        const userList = await user.list({});

        // console.log(JSON.parse(userinfo.settings).avatar);
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    ...mapMutations({
      setUser: "account/setUser",
      setToken: "account/setToken",
    }),
    ...mapGetters({
      // userSelf: "account/user",
    }),
  },
};
</script>

<style></style>
