import axios from "@/helpers/axios";

const attachment = {
  create: function (options) {
    return axios
    .post('attachment/create/', options, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    })
    .then(({ data }) => data);
  },
  delete: function (options) {
    return axios
    .post('attachment/delete/' + options.id, options)
    .then(({ data }) => data);
  },
  list: function (options) {
    return axios
    .get('attachment/list/', options)
    .then(({ data }) => data.data);
  },
  update: function (options) {
    return axios
    .post('attachment/update/' + options.id, options)
    .then(({data}) => data.data);
  },
  getOne: function (options) {
    return axios
    .get('attachment/' + options.id, options)
    .then(({data}) => data);
  },
};

export {attachment};
